import { redirect } from "@sveltejs/kit";
import { browser } from "$app/environment";
import { globalIntents, isIntent, redirectIntents, resolveIntentRedirect } from "$lib/intents";
import { confirm, getSubscriber } from "$lib/subscription-service";
import { loadUser, propagateSafeParams, unloadUser } from "$lib/utils";
import type { PageLoad } from "./$types";
import { getPsPromise } from "$lib/payments-service";

export const load: PageLoad = async ({ parent, url }) => {
  if (!browser) return;

  let { intent, productId, user } = await parent();

  /**
   * Confirm auth/log in from email
   */
  const requestId = url.searchParams.get("requestId");
  const code = url.searchParams.get("code");
  if (requestId && code) {
    try {
      await confirm(requestId, code);

      user = await getSubscriber();
      loadUser(user);
    } catch (e) {
      console.error(e);
      unloadUser();
      redirect(307, propagateSafeParams(url, "/"));
    }
  }

  /**
   * Handle logged out cases
   */
  // If there's no user, but there's an intent with an invalid value, strip query params
  if (!user && intent && !isIntent(intent, globalIntents)) {
    redirect(307, propagateSafeParams(url, "/"));
  } else if (!user) {
    return;
  }

  /**
   * Handle logged in cases
   */
  if (!intent) {
    redirect(307, propagateSafeParams(url, "/account/"));
  } else if (!isIntent(intent, globalIntents)) {
    redirect(307, propagateSafeParams(url, "/account/"));
  } else if (isIntent(intent, redirectIntents)) {
    redirect(307, propagateSafeParams(url, resolveIntentRedirect(intent, productId)));
  } else if (intent === "checkout") {
    const ps = await getPsPromise();
    await ps.purchasePlanIfNecessary(productId);
  }
};

<script lang="ts">
  import { fade, slide } from "svelte/transition";
  import { quintOut } from "svelte/easing";
  import confetti from "canvas-confetti";
  import { browser } from "$app/environment";
  import { auth } from "$lib/subscription-service";
  import { getProductByKey } from "$lib/utils";
  import Spinner from "$lib/components/Spinner.svelte";
  import ProductLanding from "$lib/components/ProductLanding.svelte";
  import type { PageData } from "./$types";

  // NOTE: do I need this?
  import { isFetching } from "./+layout.svelte";

  export let data: PageData;
  $: ({ intent, productKey, uxMode } = data);
  $: product = getProductByKey(productKey);

  let confettiCanvas;
  let emailSubmitted = false;
  let isSending = false;
  let congratsConfetti = () => {};

  if (browser) {
    /**
     * Trigger login email (and confetti)
     */
    congratsConfetti = confetti.create(confettiCanvas, {
      resize: true,
    });

    // set options
    congratsConfetti = congratsConfetti.bind(null, {
      particleCount: 150,
      spread: 180,
      origin: {
        y: 1,
      },
      disableForReducedMotion: true,
    });

    // TODO: determine if necessary
    if (location.search.includes("requestId")) {
      $isFetching = true;
    }
  }

  let email = "";
  $: emailError = emailError && !email;
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email.length >= 1000 || !/\S+@\S+\.\S+/.test(email)) {
      emailError = true;
      email = "";
      return;
    }

    let productId = product && product.id;

    try {
      isSending = true;
      if (intent && productId) {
        await auth(email, uxMode, intent, productId);
      } else {
        await auth(email, uxMode);
      }
      isSending = false;
    } catch (e) {
      emailError = true;
      email = "";
      isSending = false;
      return;
    }

    emailSubmitted = true;
    congratsConfetti();
  };
</script>

{#if emailSubmitted}
  <div in:fade={{ duration: 600 }} class="flex flex-col items-center text-white">
    <h1 class="pb-7 text-center text-h3 sm:text-h2">A login email is on the way</h1>
    <p class="pb-4 text-center">Click on the secure login link in the email to access your account.</p>
    <p class="text-center">
      Don’t see the email? Check your spam folder or <button
        class="underline"
        type="button"
        on:click={() => (emailSubmitted = false)}>try again</button
      >.
    </p>
  </div>
{:else if intent === "recover" && product}
  {@const isSupported = product.browserSupport.isSupported}
  <div in:fade={{ duration: 600 }} class="flex flex-col items-center text-white">
    <h1 class="pb-6 text-h4 xs:text-h3 sm:text-h2 !leading-snug text-center">Start using {product.name} on this device</h1>
    <p class="pb-6 text-center">
      Restore your VPN subscription on this device by logging in to your Brave account.
    </p>
    <form class="flex w-full max-w-xl flex-wrap sm:flex-nowrap" class:-errors={emailError}>
      <div class="mb-2 w-full flex-grow sm:mr-4 sm:w-auto">
        <input bind:value={email} class="c:input c:input--large w-full" placeholder="Email address" type="email" />
        {#if emailError}
          <p class="c:error__message theme--ghost w-full" in:slide={{ duration: 100, easing: quintOut }}>
            Please enter a valid email address.
          </p>
        {/if}
      </div>
      <button
        disabled={email.length <= 0 || emailError || isSending || !isSupported}
        on:click={handleSubmit}
        class="c:btn c:btn--ghost c:btn--large flex-grow sm:flex-grow-0"
        type="submit"
        class:loading={isSending}
        title={!isSupported ? "Your browser isn't supported by this product." : undefined}
      >
        {#if isSending}
          <Spinner fill="currentColor" delay={false} fillContainer={false} />
          Loading...
        {:else}
          Get login link
        {/if}
      </button>
    </form>
    <p class="pt-6 text-center">
      Enter your email address to get a secure login link.
    </p>
  </div>
{:else if intent === "checkout" && product}
  {@const btnText = product.hasTrial ? "Start free trial" : "Go premium"}
  <ProductLanding {product}>
    <section
      let:isSupported
      slot="lower-content"
      class="flex flex-col items-center rounded-b-3xl border-t border-white border-opacity-35 bg-white bg-opacity-[0.08] px-8 pb-12 pt-6 text-white backdrop-blur-[32px]"
    >
      <form class="flex w-full flex-wrap sm:flex-nowrap" class:-errors={emailError}>
        <div class="mb-2 w-full flex-grow sm:mr-4 sm:w-auto">
          <input bind:value={email} class="c:input c:input--large w-full" placeholder="Email address" type="email" />
          {#if emailError}
            <p class="c:error__message theme--ghost w-full" in:slide={{ duration: 100, easing: quintOut }}>
              Please enter a valid email address.
            </p>
          {/if}
        </div>
        <button
          disabled={email.length <= 0 || emailError || isSending || !isSupported}
          on:click={handleSubmit}
          class="c:btn c:btn--ghost c:btn--large flex-grow sm:flex-grow-0"
          type="submit"
          class:loading={isSending}
          title={!isSupported ? "Your browser isn't supported by this product." : undefined}
        >
          {#if isSending}
            <Spinner fill="currentColor" delay={false} fillContainer={false} />
            Loading...
          {:else}
            {btnText}
          {/if}
        </button>
      </form>
      <p class="pt-4">
        You're just a step away from {product.name}. Just enter your email address and click <strong>{btnText}</strong> to
        get started. (You'll finish up on our Stripe payments page.)
      </p>
    </section>
  </ProductLanding>
{:else}
  <div in:fade={{ duration: 600 }} class="flex flex-col items-center text-white">
    <h1 class="pb-7 text-h4 xs:text-h3 sm:text-h2">Log in, or create an account</h1>
    <form class="flex flex-wrap justify-center" class:-errors={emailError}>
      <div>
        <input
          bind:value={email}
          class="c:input c:input--large w-full sm:mr-4 sm:w-auto"
          placeholder="Email address"
          type="email"
        />
        {#if emailError}
          <p class="c:error__message theme--ghost w-full" in:slide={{ duration: 100, easing: quintOut }}>
            Please enter a valid email address.
          </p>
        {/if}
      </div>
      <button
        disabled={email.length <= 0 || emailError}
        on:click={handleSubmit}
        class="c:btn c:btn--ghost c:btn--large mt-4 w-[250px] sm:mt-0 sm:w-auto"
        type="submit"
        class:loading={isSending}
      >
        {#if isSending}
          <Spinner fill="currentColor" delay={false} fillContainer={false} />
          Loading...
        {:else}
          Get login link
        {/if}
      </button>
    </form>
    <p class="pt-6 text-center">
      Enter your email address to get a secure login link. Use this link to either create or access a Premium account,
      and complete your purchase.
    </p>
  </div>
{/if}

<canvas bind:this={confettiCanvas} />

<style lang="scss">
  :global(.main) {
    padding-top: calc(30vh - 111px);
  }
</style>

<script>
  import { getFormattedPrice } from "$lib/utils";
  import { onMount } from "svelte";

  import { fade } from "svelte/transition";

  export let product;

  const { name, icon, description, features, gradient, price, hasTrial, trialDays, browserSupport } = product;

  onMount(() => {
    document.body.classList.add("product-landing");

    return () => {
      document.body.classList.remove("product-landing");
    };
  });
</script>

<section
  in:fade={{ duration: 600 }}
  class="plan theme--dark overflow-hidden rounded-t-3xl bg-white bg-opacity-[0.08] p-8 pb-4 text-white backdrop-blur-[32px]"
>
  <header class="flex items-center">
    <img class="mr-4 h-[72px] w-[72px] xxs:mr-8" width="120" height="120" src={`/icons/${icon}`} alt={`${name} icon`} />
    <div class="flex w-full flex-wrap items-center pb-3 sm:pb-0 md:flex-nowrap">
      <h1 class="text-h3 sm:text-h4 md:pr-3">{name}</h1>
      <div class="flex w-full flex-col md:ml-auto md:w-auto md:items-end">
        {#if hasTrial}
          <span class="text-h5 font-semibold sm:text-h6">{trialDays}-day free trial</span>
        {/if}
        <span class="text-h5 font-semibold sm:text-h6"
          >{#if hasTrial}<span class="text-sm">then</span>
          {/if}{getFormattedPrice(price)}<span class="text-sm text-dark-02">/month</span></span
        >
      </div>
    </div>
  </header>
  <section class="flex flex-wrap sm:flex-nowrap sm:pt-4">
    <p class="min-w-[60%] text-base font-medium sm:pr-14 sm:text-sm">
      {description}
      <!-- <a class="c:btn c:btn--text mt-3 block max-w-max text-white" href={link} aria-label="Learn more about {name}">Learn more</a> -->
    </p>
    <ul class="min-w-[40%] pt-4 sm:pt-0">
      {#each features as feature}
        <li class="plan__feature flex pb-3 text-base font-medium sm:text-sm">
          <svg class="o:icon mr-3 flex-shrink-0" style={`--color: url(${gradient});`}>
            <use href="/images/brave-icons.svg#check" />
          </svg>
          {feature}
        </li>
      {/each}
    </ul>
  </section>
  {#if !browserSupport.isSupported}
    <p class="mt-4 text-center text-tiny font-semibold">
      {browserSupport.message}
    </p>
  {/if}
</section>

<slot name="lower-content" isSupported={browserSupport.isSupported} />

<style>
  .plan__feature .o\:icon {
    --color: theme("colors.blue.600");
  }

  :global(.product-landing .main) {
    padding-top: 0;
    max-width: 615px;
  }

  @screen sm {
    :global(.product-landing .main) {
      padding-top: calc(20vh - 111px);
    }
  }
</style>
